import api from './api'

export default {
    addCarrier(data){
    return api.execute(`post`,`/manager/carrier`, data)
    },
    // getCarriers(){
    //     return api.execute(`get`,`/manager/carrier`)
    // },

    // getCarriers(page, limit) {
    getCarriers(payload) {
        // return api.execute('get', `/manager/carrier?page=${page}&limit=${limit}`);
        return api.execute(`get`,`/manager/carrier`+ payload);
    },

    deleteCarrier(data){
        return api.execute(`delete`,`/manager/carrier`, data)
    },
    editCarrier(data){
        return api.execute(`put`,`/manager/carrier`,data)
    }
}
